import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./Containers/Login";
import Header from "./Containers/Header";
import Tickets from "./Containers/Tickets";
import Tasks from "./Containers/Tasks";
import "./App.css";

function App() {
  const [pathName, setPathName] = useState();

  useEffect(() => {
    let path = window.location.href.split("/")[3];
    setPathName(path.toString());
  }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        {pathName === "login" || pathName === "" ? null : <Header />}
        <React.Suspense fallback={"loading"}>
          <Switch>
            <Route path={"/login"} component={Login} />
            <Route path={"/tickets"} component={Tickets} />
            <Route path={"/tasks"} component={Tasks} />
            <Redirect from="/" to="/login" />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Button, Dialog, Grid, Typography, Zoom } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Dropdown from "../Dropdown";
import DobPicker from "../dobPicker";
import Input from "../Input";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  newTask,
  taskList,
  taskRowUpdate,
} from "../../Redux/TaskApis/Task.action";
import { dateToyyymmdd } from "../../HelperFunctions/TimeDateTimestamp";
import { Autocomplete } from "@material-ui/lab";
import { ticketList } from "../../Redux/TicketApi/Ticket.action";
import toast from "react-hot-toast";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import "./style.css";

function NewTaskDialog(props) {
  
  const Status = [
    // { name: "Open", value: 5 },
    { name: "Not started", value: 1 },
    { name: "In progress", value: 2 },
    { name: "Completed", value: 3 },
    { name: "Cancelled", value: 4 },
    { name: "Resolved", value: 6 },
  ];

  const Priority = [
    { name: "High", value: 1 },
    { name: "Medium", value: 2 },
    { name: "Low", value: 3 },
  ];

  const [values, setValues] = useState({
    clinic: null,
    subject: null,
    ticket: null,
    from: null,
    fromName: null,
    owner: null,
    ownerName: null,
    dueDate: null,
    status: null,
    priority: null,
    description: null,
    clinicId: null,
    type: null,
    isTicketSubject: false,
    ticketSubject: null,
    taskId: null,
  });

  const [searchTicket, setSearchTicket] = useState([]);
  const [user, setUser] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [submitted, setSubmitted] = useState(false);
  const [clinicList, setClinicList] = useState([]);
  const [workFlowType, setWorkFlowType] = useState([]);

  let currentTab = localStorage.getItem("currentTab");

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem("cache"));
    let clinicList = [];
    let usersList = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    cache.userDtoList.forEach((x) => {
      usersList.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // Sort the owner array alphabetically by name
    usersList.sort((a, b) => a.name.localeCompare(b.name));
    setClinicList(clinicList);
    setUser(usersList);
  }, []);

  const handleSubmit = (e) => {
    
    e.preventDefault();
    // e.stopPropagation();
    // setSubmitted(true);
    // props.newTask({
    //   ticketTaskId: values.taskId,
    //   ticketId:
    //     currentTab === "tasks" ? values.ticket : props.ticketInfo.ticketId,
    //   workflowStepId: null,
    //   workflowType: values.type,
    //   assignedBy: values.from,
    //   assignedToRole: null,
    //   assignedTo: values.owner,
    //   dueDate: values.dueDate
    //     ? dateToyyymmdd(values.dueDate) + "T12:00:00.000Z"
    //     : null, //+ "T00:00:00.000+00:00",
    //   status: values.status,
    //   reminderOn: null,
    //   clinicId:
    //     currentTab === "tasks" ? values.clinicId : props.ticketInfo.clinicId,
    //   priority: values.priority,
    //   description: values.description,
    //   subject: values.subject,
    // });
    if (props.taskInfo) {
      props.taskRowUpdate({
        ticketTaskId: values.taskId,
        ticketId:
          currentTab === "tasks" ? values.ticket : props.taskInfo.ticketId,
        workflowStepId: null,
        assignedBy: values.from,
        assignedToRole: null,
        assignedTo: values.owner,
        dueDate: values.dueDate
          ? dateToyyymmdd(values.dueDate) + "T12:00:00.000Z"
          : null,
        status: values.status,
        reminderOn: null,
        clinicId: values.clinicId,
        priority: values.priority,
        description: values.description,
        subject: values.subject,
        workflowType: values.type,
        id: values.taskId,
      });
    } else {
      props.newTask({
        ticketTaskId: values.taskId,
        ticketId:
          currentTab === "tasks" ? values.ticket : props?.ticketInfo?.ticketId,
        workflowStepId: null,
        workflowType: values.type,
        assignedBy: values.from,
        assignedToRole: null,
        assignedTo: values.owner,
        dueDate: values.dueDate
          ? dateToyyymmdd(values.dueDate) + "T12:00:00.000Z"
          : null, //+ "T00:00:00.000+00:00",
        status: values.status,
        reminderOn: null,
        clinicId:
          currentTab === "tasks" ? values.clinicId : props?.ticketInfo?.clinicId,
        priority: values.priority,
        description: values.description,
        subject: values.subject,
      });
    }
  };

  const handleClose = () => {
    props.handleClose();
    // setSubmitted(false);
    setValues({
      clinic: null,
      subject: null,
      ticket: null,
      from: null,
      owner: null,
      dueDate: null,
      status: null,
      priority: null,
      description: null,
      type: null,
      isTicketSubject: false,
      taskId: null,
    });
  };

  const searchTickets = (e) => {
    const user = JSON.parse(localStorage.getItem("owner"));
    if (e.target.value.length >= 1) {
      props.ticketList({
        start: 0,
        limit: 200,
        sortBy: null,
        sortDirection: null,
        ticketDto: {
          ticketId: null,
          ticketNumber: null,
          subject: null,
          priority: null,
          currentStatus: null,
          currentAssignedTo: null,
          currentWorkflowStepId: null,
          dueDate: null,
          clinicId: null,
          createdBy: null,
          workflowType: null,
          loggedUser: user.userId,
        },
      });
      setSearchValue(e.target.value);
    }
    if (e.target.value.length === 0) {
      setValues({ ...values, clinic: null });
    }
  };

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };

  useEffect(() => {
    if (props.Tickets.ticketTable) {
      let data = props.Tickets.ticketTable.response.data;
      let ticketDtoList = data.result.ticketDtoList;
      let tickets = ticketDtoList
        ? ticketDtoList.map((x) => {
            return {
              ticket: x.subject
                ? x.ticketNumber +
                  " - " +
                  sentanceCase(x.subject.substring(0, 45))
                : x.ticketNumber,
              id: x.ticketId,
              subject: x.subject,
              clinicId: x.clinicId,
              clinicName: x.clinicName ? x.clinicName.substring(0, 20) : "",
            };
          })
        : "";
      if (searchValue) {
        setSearchTicket(tickets);
      }
      if (ticketDtoList.length > 0) {
        setValues({
          ...values,
          ticketSubject: tickets ? tickets[0].ticket : "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Tickets.ticketTable]);

  const selectTicketId = (e) => {
    if (e) {
      setValues({
        ...values,
        ticket: e.id,
        clinic: e.clinicName,
        clinicId: e.clinicId,
      });
    } else {
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("owner"));
    // let filter = props.Task.taskFilter;
    if (props.Task.newTask) {
      let data = props.Task.newTask.response.data;
      if (data.ticketTaskId) {
        toast.success("Task added successfully");
        props.handleClose();
        // setSubmitted(false);
        props.taskList({
          start: 0,
          limit: 20,
          sortBy: null,
          sortDirection: null,
          ticketTaskDto: {
            ticketTaskId: null,
            ticketId: null,
            workflowStepId: null,
            assignedBy: null,
            assignedToRole: null,
            assignedToUser: null,
            assignedOn: null,
            dueDate: null,
            status: null,
            reminderOn: null,
            clinicId: null,
            workflowType: null,
            priority: null,
            loggedUser: user.userId,
          },
        });
        setValues({
          clinic: null,
          subject: null,
          ticket: null,
          from: null,
          owner: null,
          dueDate: null,
          status: null,
          priority: null,
          description: null,
          type: null,
          isTicketSubject: false,
          taskId: null,
        });
        props.Task.newTask = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.Task.newTask]);

  useEffect(() => {
    if (props.taskInfo) {
      setValues({
        clinic: props.taskInfo.clinicName,
        subject: props.taskInfo.subject,
        from: props.taskInfo.from,
        fromName: props.taskInfo.fromName,
        owner: props.taskInfo.owner,
        ownerName: props.taskInfo.ownerName,
        dueDate: props.taskInfo.dueDate,
        status: props.taskInfo.status,
        priority: props.taskInfo.priority,
        description: props.taskInfo.description,
        clinicId: props.taskInfo.clinicId,
        type: props.taskInfo.type,
        isTicketSubject: props.taskInfo.isTicketSubject,
        taskId: props.taskInfo.taskId,
        ticket: props.taskInfo.ticketId,
      });
    }
  }, [props.taskInfo]);

  useEffect(() => {
    if (props.Tickets.typeList) {
      let response = props.Tickets.typeList.response;
      if (response.status === 200) {
        let data = response.data;
        let typeList = [];
        data.forEach((x) => {
          const name =
            x.workFlow.length > 2
              ? x.workFlow.charAt(0).toUpperCase() +
                x.workFlow.slice(1).toLowerCase()
              : x.workFlow;

          typeList.push({
            name,
            value: x.workflowMasterId,
          });
        });
        setWorkFlowType(typeList);
      }
    }
  }, [props.Tickets.typeList]);

  return (
    <div>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={props.open}
        // onClose={props.handleClose}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="ticket_create_dialog"
        TransitionComponent={Zoom}
        TransitionProps={{
          timeout: 300,
        }}
      >
        <form onSubmit={handleSubmit}>
          <div className="ticket_create_container">
            <div className="close_icon">
              <Typography
                style={{
                  fontWeight: "600",
                  paddingBottom: "0.5rem",
                  fontSize: "1.2rem",
                }}
              >
                Task Information
              </Typography>
              <CloseIcon onClick={handleClose} />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  required
                  type="text"
                  label="Subject"
                  onChange={(e) => {
                    setValues({ ...values, subject: e.target.value });
                  }}
                  value={values.subject}
                  // validation={(value) =>
                  //   submitted
                  //     ? value
                  //       ? value.length >= 3
                  //         ? value.length > 45
                  //           ? "maximum support 45 characters"
                  //           : ""
                  //         : "Must be 3 characters"
                  //       : "This field is required"
                  //     : ""
                  // }
                />
              </Grid>{" "}
              <Grid item xs={12}>
                {localStorage.getItem("currentTab") === "tickets" ? (
                  <Input
                    readOnly
                    type="text"
                    label="Ticket"
                    value={
                      props.ticketInfo.number + " - " + props.ticketInfo.subject
                    }
                  />
                ) : values.isTicketSubject ? (
                  <Input
                    label="Ticket"
                    readOnly
                    value={values.ticketSubject ? values.ticketSubject : ""}
                  />
                ) : (
                  <div>
                    <Autocomplete
                      fullWidth
                      options={searchTicket}
                      getOptionLabel={(option) => option.ticket}
                      onChange={(e, i) => selectTicketId(i)}
                      renderInput={(params) => (
                        <Input
                          label="Ticket"
                          placeholder="Search your ticket..."
                          {...params}
                          onChange={searchTickets}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment>
                                <SearchIcon
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    color: "grey",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={4}>
                {localStorage.getItem("currentTab") === "tickets" ? (
                  <Input
                    label="Clinic Name"
                    readOnly
                    value={
                      props.ticketInfo.clinicName
                        ? props.ticketInfo.clinicName
                        : ""
                    }
                  />
                ) : (
                  // <Input
                  //   label="Clinic Name"
                  //   readOnly
                  //   value={values.clinic ? values.clinic : ""}
                  // />
                  <Dropdown
                    label="Clinic Name"
                    options={clinicList}
                    value={values.clinicId ? values.clinicId : ""}
                    onChange={(e) => {
                      setValues({ ...values, clinicId: e.target.value });
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                {/* <GroupedDropdown
                  formControlStyle={{ width: "155px" }}
                  user={user}
                  inputLabel="From"
                  defaultValue={{
                    value: values.from ? values.from : "",
                    label: values.fromName ? values.fromName : "",
                  }}
                  onChange={(value) => {
                    setValues({ ...values, from: value });
                  }}
                /> */}
                <Dropdown
                  label="From"
                  options={user}
                  onChange={(e) => {
                    setValues({ ...values, from: e.target.value });
                  }}
                  value={values.from}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <GroupedDropdown
                  formControlStyle={{ width: "155px" }}
                  user={user}
                  inputLabel="Task Owner"
                  defaultValue={{
                    value: values.owner ? values.owner : "",
                    label: values.ownerName ? values.ownerName : "",
                  }}
                  onChange={(value) => {
                    setValues({ ...values, owner: value });
                  }}
                /> */}
                <Dropdown
                  label="Task Owner"
                  options={user}
                  onChange={(e) => {
                    setValues({ ...values, owner: e.target.value });
                  }}
                  value={values.owner}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Type"
                  options={workFlowType}
                  onChange={(e) => {
                    setValues({ ...values, type: e.target.value });
                  }}
                  value={values.type}
                />
              </Grid>
              <Grid item xs={4}>
                <DobPicker
                  inputVariant={"standard"}
                  label="Due Date"
                  onChange={(e) => {
                    setValues({ ...values, dueDate: e.target.value });
                  }}
                  value={values.dueDate ? values.dueDate : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Status"
                  options={Status}
                  onChange={(e) => {
                    setValues({ ...values, status: e.target.value });
                  }}
                  value={values.status}
                />
              </Grid>
              <Grid item xs={4}>
                <Dropdown
                  label="Priority"
                  options={Priority}
                  onChange={(e) => {
                    setValues({ ...values, priority: e.target.value });
                  }}
                  value={values.priority}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="description" style={{ color: "grey" }}>
                  Description
                </label>
                <textarea
                  className="text_area"
                  name=""
                  id=""
                  cols="60"
                  rows="1"
                  type="text"
                  onChange={(e) => {
                    setValues({ ...values, description: e.target.value });
                  }}
                  value={values.description}
                  style={{
                    fontSize: "16px",
                    outline: "none",
                    fontFamily: "Inter, sans-serif",
                    maxWidth: "500px",
                    border: "none",
                    borderBottom: "1px solid grey",
                  }}
                ></textarea>
                {/* {submitted && (
                  <div className="des_error_message">
                    {values.description
                      ? validateDescription(values.description)
                      : ""}
                  </div>
                )} */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="newTicketBtn">
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </div>
            </Grid>
          </div>
        </form>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Tickets: state.Ticket,
  Task: state.Task,
});

const mapDispatchToProps = (dispatch) => ({
  newTask: (values) => dispatch(newTask(values)),
  taskList: (values) => dispatch(taskList(values)),
  ticketList: (values) => dispatch(ticketList(values)),
  taskRowUpdate: (values) => dispatch(taskRowUpdate(values)),
});
NewTaskDialog.propTypes = {
  newTask: PropTypes.func,
  taskList: PropTypes.func,
  ticketList: PropTypes.func,
  taskRowUpdate: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTaskDialog);
